Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform3";
exports.labelBodyText = "customform3 Body";
exports.apiGetCountryCodeContentType = "application/json";
exports.apiGetCountryCodeEndPoint = "account/accounts/country_code_and_flag";
exports.apiGetCountryMethod = "GET";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End