import React from "react";
import { Box } from "@material-ui/core";
import { useFormikContext } from "formik";
import { FormValues } from "../CustomMemorandumForm.web";
import InformationComponent from "./InformationComponent";

const SellerSectionInformation = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <Box>
      <InformationComponent name="Name -" value={values.sellerSection.name} />
      <InformationComponent
        name="Residential/Correspondence Address -"
        value={values.sellerSection.address}
      />
      <InformationComponent
        name="Phone Number -"
        value={values.sellerSection.phoneNumber}
      />
      <InformationComponent name="Email -" value={values.sellerSection.email} />
      <InformationComponent
        name="Will a holding deposit be paid? -"
        value={
          values.sellerSectionOtherInformation.holdingDepositWillBePaid
            ? "Yes"
            : "No"
        }
      />
      {values.sellerSectionOtherInformation.holdingDepositWillBePaid && (
        <InformationComponent
          name="If yes, how much will be paid? -"
          value={values.sellerSectionOtherInformation.howMuchWillBePaid}
        />
      )}
      <InformationComponent
        name="Has the deposit been verified/Confirmed? -"
        value={
          values.sellerSectionOtherInformation.depositVerified ? "Yes" : "No"
        }
      />
      <InformationComponent
        name="Expected date for exchange -"
        value={values.sellerSectionOtherInformation.exchangeDate}
      />
      <InformationComponent
        name="Expected date of completion -"
        value={values.sellerSectionOtherInformation.completionDate}
      />
      <InformationComponent
        name="Special conditions for sale -"
        value={values.sellerSectionOtherInformation.specialConditions}
      />
      <InformationComponent
        name="Is the Vendor buying another property? -"
        value={values.sellerSectionOtherInformation.vendorAnotherProperty}
      />
      <InformationComponent
        name="Details of the Onward chase -"
        value={values.sellerSectionOtherInformation.chaseDetails}
      />
      <InformationComponent
        name="Any Legal issues to Note? -"
        value={
          values.sellerSectionOtherInformation.haveLegalIssues ? "Yes" : "No"
        }
      />
      {values.sellerSectionOtherInformation.haveLegalIssues && (
        <InformationComponent
          name="If yes, please specify -"
          value={values.sellerSectionOtherInformation.legalIssues}
        />
      )}
      <InformationComponent
        name="Fixture and fittings including in the sale -"
        value={values.sellerSectionOtherInformation.fixtureFittingsOnSale}
      />
      <InformationComponent
        name="Extra notes/Observations -"
        value={values.sellerSectionOtherInformation.extraNotes}
      />
    </Box>
  );
};

export default SellerSectionInformation;
