import React from "react";
// Customizable Area Start
import { Box, Button, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import UserInformationForm from "./components/UserInformationForm.web";
import EditableInput from "./components/EditableInput.web";
import PropertyTenureMenu from "./components/PropertyTenureMenu.web";
import SellerSectionForm from "./components/SellerSectionForm.web";
import BuyerSectionForm from "./components/BuyerSectionForm.web";

const theme = createTheme({
  palette: {},
});

interface UserInformation {
  name: string;
  address: string;
  phoneNumber: string;
  email: string;
}

export enum PropertyTenure {
  "FREEHOLD" = "Freehold",
  "LEASEHOLD" = "Leasehold",
}

export interface FormValues {
  price: string;
  registryNumber: string;
  sellerInformation: UserInformation;
  buyerInformation: UserInformation;
  yearsRemaining: string;
  propertyTenure: PropertyTenure;
  sellerSection: UserInformation;
  sellerSectionOtherInformation: {
    holdingDepositWillBePaid: boolean;
    howMuchWillBePaid: string;
    depositVerified: boolean;
    exchangeDate: string;
    completionDate: string;
    vendorAnotherProperty: string;
    specialConditions: string;
    chaseDetails: string;
    haveLegalIssues: boolean;
    legalIssues: string;
    fixtureFittingsOnSale: string;
    extraNotes: string;
  };
  haveConveyancer: boolean;
  buyerSection: UserInformation;
  buyerSectionOtherInformation: {
    isQuickCash: boolean;
    decisionInPrinciple: boolean;
    amountOfMortgage: string;
    nameOfMortgage: string;
    countryNumberOfMortgage: string;
    phoneNumberOfMortgage: string;
    hasOwnershipChecked: boolean;
    extraNotes: string;
    specialConditions: string;
    isPropertyBoughtInCompanyName: boolean;
    haveLegalIssues: boolean;
    legalIssues: string;
  };
}
// Customizable Area End

import Customform3Controller, { Props } from "./Customform3Controller";

export default class CustomMemorandumForm extends Customform3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const isSeller = false;
    const isAcceptingProcess = false;
    const isRevisionProcess = false;

    const canEditSellerSectionForm = isSeller || isRevisionProcess;
    const canEditBuyerSectionForm = !isSeller && !isAcceptingProcess;

    const memorandumFormSchema = {
      price: canEditSellerSectionForm ? Yup.string().required() : Yup.string(),
      registryNumber: canEditSellerSectionForm
        ? Yup.string().required()
        : Yup.string(),
      sellerInformation: canEditSellerSectionForm
        ? Yup.object({
            name: Yup.string().required(),
            address: Yup.string().required(),
            phoneNumber: Yup.string().required(),
            email: Yup.string().required(),
          })
        : Yup.object({}),
      yearsRemaining: Yup.string().when("propertyTenure  ", {
        is: (propertyTenure) => propertyTenure === PropertyTenure.LEASEHOLD,
        then: Yup.string().required(),
      }),
      sellerSection: canEditSellerSectionForm
        ? Yup.object({
            name: Yup.string().required(),
            address: Yup.string().required(),
            phoneNumber: Yup.string().required(),
            email: Yup.string().required(),
          })
        : Yup.object({}),
      sellerSectionOtherInformation: canEditSellerSectionForm
        ? Yup.object({
            holdingDepositWillBePaid: Yup.boolean().required(),
            howMuchWillBePaid: Yup.string().when("holdingDepositWillBePaid  ", {
              is: (holdingDepositWillBePaid) =>
                holdingDepositWillBePaid === true,
              then: Yup.string().required(),
            }),
            chaseDetails: Yup.string().required(),
            exchangeDate: Yup.string().required(),
            completionDate: Yup.string().required(),
            haveLegalIssues: Yup.boolean().required(),
            legalIssues: Yup.string().when("haveLegalIssues  ", {
              is: (haveLegalIssues) => haveLegalIssues === true,
              then: Yup.string().required(),
            }),
            specialConditions: Yup.string(),
            fixtureFittingsOnSale: Yup.string().required(),
            extraNotes: Yup.string(),
            vendorAnotherProperty: Yup.string().required(),
          })
        : Yup.object({}),
      haveConveyancer: Yup.boolean().required(),
      buyerSection: Yup.object().when("haveConveyancer", {
        is: (haveConveyancer) =>
          haveConveyancer === true && canEditBuyerSectionForm,
        then: Yup.object({
          name: Yup.string().required(),
          address: Yup.string().required(),
          phoneNumber: Yup.string().required(),
          email: Yup.string().required(),
        }),
      }),

      buyerSectionOtherInformation: canEditBuyerSectionForm
        ? Yup.object({
            isQuickCash: Yup.boolean().required(),
            decisionInPrinciple: Yup.boolean().required(),
            amountOfMortgage: Yup.string().required(),
            nameOfMortgage: Yup.string().required(),
            phoneNumberOfMortgage: Yup.string().required(),
            countryNumberOfMortgage: Yup.string().required(),
            hasOwnershipChecked: Yup.boolean().required(),
            extraNotes: Yup.string(),
            specialConditions: Yup.string(),
            isPropertyBoughtInCompanyName: Yup.boolean().required(),
            haveLegalIssues: Yup.boolean().required(),
            legalIssues: Yup.string().when("haveLegalIssues  ", {
              is: (haveLegalIssues) => haveLegalIssues === true,
              then: Yup.string().required(),
            }),
          })
        : Yup.object({}),
    };

    const initialValues: FormValues = {
      price: "459.9999",
      registryNumber: "HLR 123478",
      sellerInformation: {
        name: "Harry Reo",
        address: "299, samee yan, ontario, London, 98765",
        phoneNumber: "+44 9876543212",
        email: "abcd@gmail.com",
      },
      buyerInformation: {
        name: "Harry Reo",
        address: "299, samee yan, ontario, London, 98765",
        phoneNumber: "+44 9876543212",
        email: "abcd@gmail.com",
      },
      propertyTenure: PropertyTenure.LEASEHOLD,
      yearsRemaining: "999",
      sellerSection: {
        name: "Harry Reo",
        address: "299, samee yan, ontario, London, 98765",
        phoneNumber: "9876543212",
        email: "abcd@gmail.com",
      },
      sellerSectionOtherInformation: {
        holdingDepositWillBePaid: true,
        howMuchWillBePaid: "Lorem Ipsum",
        depositVerified: true,
        exchangeDate: "32/10/20201",
        completionDate: "32/10/20201",
        vendorAnotherProperty: "Lorem Ipsum",
        specialConditions: "Lorem Ipsum",
        chaseDetails: "Lorem Ipsum",
        haveLegalIssues: true,
        legalIssues: "Lorem Ipsum",
        fixtureFittingsOnSale: "Lorem Ipsum",
        extraNotes: "Lorem Ipsum",
      },
      haveConveyancer: true,
      buyerSection: {
        name: "Harry Reo",
        address: "299, samee yan, ontario, London, 98765",
        phoneNumber: "9876543212",
        email: "abcd@gmail.com",
      },
      buyerSectionOtherInformation: {
        isQuickCash: true,
        decisionInPrinciple: true,
        amountOfMortgage: "Lorem Ipsum",
        nameOfMortgage: "Lorem Ipsum",
        phoneNumberOfMortgage: "11111",
        countryNumberOfMortgage: "76543",
        hasOwnershipChecked: true,
        extraNotes: "Lorem Ipsum",
        specialConditions: "Lorem Ipsum",
        isPropertyBoughtInCompanyName: true,
        haveLegalIssues: true,
        legalIssues: "Lorem Ipsum",
      },
    };

    return (
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.titleContainer}>
          <Typography style={webStyle.titleText}>Memorandum of Sale</Typography>
          <Typography style={webStyle.titleText}>
            369, shree colony,Ontario, London, 65433
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape(memorandumFormSchema)}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <EditableInput
                label="Price-"
                name="price"
                textValue={`£${values.price}`}
                canEdit={canEditSellerSectionForm}
              />
              <EditableInput
                label="HM Land Registry Number-"
                name="registryNumber"
                textValue={values.registryNumber}
                canEdit={canEditSellerSectionForm}
              />
              <UserInformationForm
                label="Seller Information"
                objectName="sellerInformation"
                canEdit={canEditSellerSectionForm}
              />
              {console.log(
                "canEditSellerSectionForm",
                canEditSellerSectionForm
              )}
              <UserInformationForm
                label="Buyer Information"
                objectName="buyerInformation"
                canEdit={canEditBuyerSectionForm}
              />
              {console.log("canEditBuyerSectionForm", canEditBuyerSectionForm)}
              <PropertyTenureMenu
                textValue={values.propertyTenure}
                canEdit={canEditSellerSectionForm}
              />
              {values.propertyTenure === PropertyTenure.LEASEHOLD && (
                <EditableInput
                  label="Years Remaining on Lease-"
                  name="yearsRemaining"
                  textValue={values.yearsRemaining}
                  canEdit={canEditSellerSectionForm}
                />
              )}
              <SellerSectionForm canEdit={canEditSellerSectionForm} />
              <BuyerSectionForm
                getCountryCodeData={this.state.getCountryCodeData}
                canEdit={canEditBuyerSectionForm}
                isAcceptingProcess={isAcceptingProcess || isRevisionProcess}
              />
              <Box style={webStyle.submitButtonContainer}>
                {isAcceptingProcess ? (
                  <>
                    <Button style={webStyle.declineButton}>Decline</Button>
                    <Button style={webStyle.acceptButton}>Accept</Button>
                  </>
                ) : (
                  <Button style={webStyle.submitButton} type="submit">
                    Submit
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
interface StylesDictionary {
  [Key: string]: React.CSSProperties;
}

const webStyle: StylesDictionary = {
  titleContainer: {
    textAlign: "center",
    color: "#273567",
    marginBottom: "32px",
  },
  titleText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "24px",
  },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "7px",
    gap: "10px",
  },
  submitButton: {
    backgroundColor: "#273567",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    padding: "8px 16px 8px 16px",
  },
  acceptButton: {
    backgroundColor: "#273567",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    padding: "6px 66px",
  },
  declineButton: {
    backgroundColor: "#FFFFFF",
    color: "#273567",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    padding: "6px 66px",
    border: "1px solid #273567",
  },
};
// Customizable Area End
