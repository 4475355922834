import React from "react";

// Customizable Area Start

import LandingPageController, { Props } from "./LandingPageController";
import {
  Paper,
  Typography,
  FormControl,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  styled,
  Menu
} from "@material-ui/core";
import "./LandingPage.web.css";
import {locDrop} from './assets'

const ScrollBoxList = styled(Paper)({
  "& div::-webkit-scrollbar": {
    width: "5px"
  },
  "& div::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    borderRadius: "10px"
  },
  "& div::-webkit-scrollbar-thumb": {
    backgroundColor: "#848FAC80",
    borderRadius: "10px"
  }
});
const iconStyle = {
  paddingTop: "12px",
  cursor: "pointer"
};
// Customizable Area End

export default class FilterItemBox extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      anchorEl, selectedOption2, anchorElEcp, selectedOptionEcp, anchorElPF, selectedOptionPF,
      anchorElSfMax, selectedOptionSfMax,
      anchorElSfMin,selectedOptionSfMin, anchorElPropList, selectedOptionPropList,
      selectedOptionLoc, anchorElPriceMin,
      selectedOptionPriceMin,
      anchorElPriceMax,
      selectedOptionPriceMax,
      anchorElBedMax,
      selectedOptionBedMax,
      anchorElBedMin,
      selectedOptionBedMin,
      anchorElBathMin,
      selectedOptionBathMin,
      anchorElBathMax,
      selectedOptionBathMax
    } = this.state;

    const proList = [
      "Detached bungalow",
      "End terrace house",
      "Equestrian property",
      "Farm, Farmhouse",
      "Flat",
      "Land",
      "Link-detached house",
      "Marionette",
      "Mews house",
      "Mobile/park home",
      "Parking/garage",
      "Studio",
      "Semi-detached house",
      "Terraced house",
      "Apartment",
      "Terraced bungalow",
      "Town house",
      "Houseboat",
      "Lodge"
    ];

    const price = [
      "9 L",
      "10 L",
      "20 L",
      "40 L",
      "50 L",
      "60 L",
      "7 million",
      "8 million",
      "9 million",
      "10 million"
    ];
    const size = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10 "];

    const proFeatList = ["Garden", "Parking", "Conservatory", "Balcony"];
    const tenureOptions = [
      "Freehold",
      "Leasehold",
      "Common hold",
      "Inheritance",
      "Rent"
    ];

    const sqFoot = [
      "187sq.ft.",
      "200sq.ft.",
      "150sq.ft.",
      "200sq.ft.",
      "300sq.ft.",
      "250sq.ft.",
      "100sq.ft.",
      "180sq.ft."
    ];

    const epc = ["A", "B", "C", "D", "E", "F"];

    const svgIcon = `
    <svg width="10" height="5" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg" style="padding: 12px; cursor: pointer; border:2px;">
    <path d="M11.6883 11.5325C11.5325 11.5325 20.4156 2.64935 20.4156 2.64935C21.039 2.02597 21.039 1.09091 20.4156 0.467531C19.7922 -0.155846 18.8571 -0.155846 18.2338 0.467531L10.4416 8.1039L2.64935 0.467532C2.02597 -0.155845 1.09091 -0.155845 0.467532 0.467532C0.155843 0.779221 -4.76851e-07 1.09091 -4.56415e-07 1.55844C-4.35979e-07 2.02597 0.155843 2.33766 0.467532 2.64935L9.1948 11.5325C9.97402 12.1558 11.5325 11.5325 11.6883 11.5325Z" fill="#848FAC"/>
    </svg>`;
    const locIcon = `<svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg" style="padding: 12px; cursor: pointer;">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 19.9988L9.28875 19.1567C10.1838 18.1854 10.9888 17.2639 11.705 16.3874L12.2962 15.6483C14.765 12.4964 16 9.99483 16 8.14588C16 4.19924 12.6425 1 8.5 1C4.3575 1 1 4.19924 1 8.14588C1 9.99483 2.235 12.4964 4.70375 15.6483L5.295 16.3874C6.3168 17.628 7.38584 18.8318 8.5 19.9988Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.5 11.0678C10.2259 11.0678 11.625 9.74207 11.625 8.10667C11.625 6.47127 10.2259 5.14551 8.5 5.14551C6.77411 5.14551 5.375 6.47127 5.375 8.10667C5.375 9.74207 6.77411 11.0678 8.5 11.0678Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;
    const line = `<svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="paddingRight:10px">
    <line x1="0.5" y1="-2.18557e-08" x2="0.500001" y2="24" stroke="white"/>
    </svg>`;

    const { showMoreFilters } = this.state;

    return (
      <Paper style={webStyles.dropdownBox as React.CSSProperties}>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <div style={{ marginRight: "10px" }}>
                  <Typography
                    style={webStyles.locationHeading as React.CSSProperties}
                  >
                    Location
                  </Typography>
                  <FormControl style={{ width: "100%", marginTop: "10px" }}>
                    <div>
                      <Button
                        id="hoverableDark"
                        variant="contained"
                        aria-haspopup="true"
                        onClick={this.handleClickLoc}
                        style={webStyles.buttonLocation as React.CSSProperties}
                      >
                          <span style={{ color: "#FFFFFF", fontWeight: 400 }}>
                            {selectedOptionLoc}
                          </span>
                        <span>
                          <div style={{ display: "flex" }}>
                          <div
                              dangerouslySetInnerHTML={{ __html: locIcon }}
                              style={{
                                cursor: "pointer",
                                paddingLeft: "20px",
                                paddingRight: "10px",
                                paddingTop: "3px"
                              }}
                            />
                            <div
                              dangerouslySetInnerHTML={{ __html: line }}
                              style={iconStyle}
                            />
                           <div style={{padding:"12px", fontSize:"16px", fontFamily:"Poppins"}}>
                            +0 miles <span style={{marginLeft:"5px"}}><img src={locDrop}/></span>
                            </div>
                            
                          </div>
                        </span>
                      </Button>
                    </div>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div style={{ marginRight: "10px" }}>
                  <Typography
                    style={webStyles.locationHeading as React.CSSProperties}
                  >
                    Property Type
                  </Typography>
                  <FormControl style={{ width: "100%", marginTop: "10px" }}>
                    <div>
                      <Button
                        variant="contained"
                        aria-haspopup="true"
                        onClick={this.handleClickPropList}
                        style={webStyles.buttonPropType as React.CSSProperties}
                      >
                        {selectedOptionPropList === "" ? (
                          <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                            Select~
                          </span>
                        ) : (
                          <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                            {selectedOptionPropList}
                          </span>
                        )}
                        <span>
                          <div
                            dangerouslySetInnerHTML={{ __html: svgIcon }}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </Button>
                      <ScrollBoxList>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorElPropList}
                          open={Boolean(anchorElPropList)}
                          onClose={this.handleClosePropList}
                          data-test-id="handleClose5"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {proList.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              data-test-id="listhover2"
                              key={option}
                              onClick={() =>
                                this.handleOptionClickPropList(option)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </ScrollBoxList>
                    </div>
                  </FormControl>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ marginRight: "10px", width: "100%" }}>
                  <Typography
                    style={webStyles.locationHeading as React.CSSProperties}
                  >
                    Price
                  </Typography>
                  <FormControl style={{ width: "100%", marginTop: "10px" }}>
                    <div>
                      <Button
                        variant="contained"
                        aria-haspopup="true"
                        onClick={this.handleClickPriceMin}
                        style={webStyles.buttonStyle as React.CSSProperties}
                      >
                        {selectedOptionPriceMin === "" ? (
                          <span style={{ color: "#848FAC", fontWeight: 400 }}>
                            Minimum
                          </span>
                        ) : (
                          <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                            {selectedOptionPriceMin}
                          </span>
                        )}
                        <span>
                          <div
                            dangerouslySetInnerHTML={{ __html: svgIcon }}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </Button>
                      <Menu
                        id="custom-select"
                        anchorEl={anchorElPriceMin}
                        open={Boolean(anchorElPriceMin)}
                        onClose={this.handleClosePriceMin}
                        data-test-id="handleClose6"
                        style={webStyles.menuStyle as React.CSSProperties}
                        className="MenuList"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                      >
                        {price.map((option: string) => (
                          <MenuItem
                            id="listhover"
                            data-test-id="listhover3"
                            key={option}
                            onClick={() =>
                              this.handleOptionClickPriceMin(option)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </FormControl>
                </div>
                <div style={{ width: "100%" }}>
                  <FormControl style={{ width: "100%", marginTop: "34px" }}>
                    <div>
                      <Button
                        variant="contained"
                        aria-haspopup="true"
                        onClick={this.handleClickPriceMax}
                        style={webStyles.buttonStyle as React.CSSProperties}
                      >
                        {selectedOptionPriceMax === "" ? (
                          <span style={{ color: "#848FAC", fontWeight: 400 }}>
                            Maximum
                          </span>
                        ) : (
                          <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                            {selectedOptionPriceMax}
                          </span>
                        )}
                        <span>
                          <div
                            dangerouslySetInnerHTML={{ __html: svgIcon }}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </Button>
                      <Menu
                        id="custom-select"
                        anchorEl={anchorElPriceMax}
                        open={Boolean(anchorElPriceMax)}
                        onClose={this.handleClosePriceMax}
                        data-test-id="handleClose7"
                        style={webStyles.menuStyle as React.CSSProperties}
                        className="MenuList"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                      >
                        {price.map((option: string) => (
                          <MenuItem
                            id="listhover"
                            key={option}
                            data-test-id="listhover4"
                            onClick={() =>
                              this.handleOptionClickPriceMax(option)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </div>

          {!showMoreFilters && (
            <Typography
              style={webStyles.showMore as React.CSSProperties}
              onClick={this.handleShowMoreFilters}
            >
              See more filters
            </Typography>
          )}
          {showMoreFilters && (
            <>
              {/* Additional dropdowns */}
              <Grid container style={{ marginTop: "10px" }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <Typography
                      style={webStyles.locationHeading as React.CSSProperties}
                    >
                      Bedrooms
                    </Typography>
                    <FormControl style={{ width: "100%", marginTop: "10px" }}>
                      <div>
                        <Button
                          variant="contained"
                          aria-haspopup="true"
                          onClick={this.handleClickBedMin}
                          style={webStyles.buttonStyle as React.CSSProperties}
                        >
                          {selectedOptionBedMin === "" ? (
                            <span style={{ color: "#848FAC", fontWeight: 400 }}>
                              Minimum
                            </span>
                          ) : (
                            <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                              {selectedOptionBedMin}
                            </span>
                          )}
                          <span>
                            <div
                              dangerouslySetInnerHTML={{ __html: svgIcon }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Button>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorElBedMin}
                          open={Boolean(anchorElBedMin)}
                          onClose={this.handleCloseBedMin}
                          data-test-id="handleClose8"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {size.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              data-test-id="listhover5"
                              key={option}
                              onClick={() =>
                                this.handleOptionClickBedMin(option)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </FormControl>
                  </div>
                  <div style={{ width: "100%", marginRight: "10px" }}>
                    <FormControl
                      style={{
                        width: "100%",
                        marginTop: "34px",
                        marginRight: "5px"
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          aria-haspopup="true"
                          onClick={this.handleClickBedMax}
                          style={webStyles.buttonStyle as React.CSSProperties}
                        >
                          {selectedOptionBedMax === "" ? (
                            <span style={{ color: "#848FAC", fontWeight: 400 }}>
                              Maximum
                            </span>
                          ) : (
                            <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                              {selectedOptionBedMax}
                            </span>
                          )}
                          <span>
                            <div
                              dangerouslySetInnerHTML={{ __html: svgIcon }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Button>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorElBedMax}
                          open={Boolean(anchorElBedMax)}
                          onClose={this.handleCloseBedMax}
                          data-test-id="handleClose9"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {size.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              data-test-id="listhover6"
                              key={option}
                              onClick={() =>
                                this.handleOptionClickBedMax(option)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </FormControl>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <Typography
                      style={webStyles.locationHeading as React.CSSProperties}
                    >
                      Bathrooms
                    </Typography>
                    <FormControl style={{ width: "100%", marginTop: "10px" }}>
                      <div>
                        <Button
                          variant="contained"
                          aria-haspopup="true"
                          onClick={this.handleClickBathMin}
                          style={webStyles.buttonStyle as React.CSSProperties}
                        >
                          {selectedOptionBathMin === "" ? (
                            <span style={{ color: "#848FAC", fontWeight: 400 }}>
                              Minimum
                            </span>
                          ) : (
                            <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                              {selectedOptionBathMin}
                            </span>
                          )}
                          <span>
                            <div
                              dangerouslySetInnerHTML={{ __html: svgIcon }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Button>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorElBathMin}
                          open={Boolean(anchorElBathMin)}
                          onClose={this.handleCloseBathMin}
                          data-test-id="handleClose10"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {size.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              data-test-id="listhover7"
                              key={option}
                              onClick={() =>
                                this.handleOptionClickBathMin(option)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </FormControl>
                  </div>
                  <div style={{ width: "100%", marginRight: "10px" }}>
                    <FormControl
                      style={{
                        width: "100%",
                        marginTop: "34px",
                        marginRight: "5px"
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          aria-haspopup="true"
                          onClick={this.handleClickBathMax}
                          style={webStyles.buttonStyle as React.CSSProperties}
                        >
                          {selectedOptionBathMax === "" ? (
                            <span style={{ color: "#848FAC", fontWeight: 400 }}>
                              Maximum
                            </span>
                          ) : (
                            <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                              {selectedOptionBathMax}
                            </span>
                          )}
                          <span>
                            <div
                              dangerouslySetInnerHTML={{ __html: svgIcon }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Button>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorElBathMax}
                          open={Boolean(anchorElBathMax)}
                          onClose={this.handleCloseBathMax}
                          data-test-id="handleClose11"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {size.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              data-test-id="listhover8"
                              key={option}
                              onClick={() =>
                                this.handleOptionClickBathMax(option)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </FormControl>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <Typography
                      style={webStyles.locationHeading as React.CSSProperties}
                    >
                      Square Footage
                    </Typography>
                    <FormControl style={{ width: "100%", marginTop: "10px" }}>
                      <div>
                        <Button
                          variant="contained"
                          aria-haspopup="true"
                          onClick={this.handleClickSfMin}
                          style={webStyles.buttonStyle as React.CSSProperties}
                        >
                          {selectedOptionSfMin === "" ? (
                            <span style={{ color: "#848FAC", fontWeight: 400 }}>
                              Minimum
                            </span>
                          ) : (
                            <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                              {selectedOptionSfMin}
                            </span>
                          )}
                          <span>
                            <div
                              dangerouslySetInnerHTML={{ __html: svgIcon }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Button>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorElSfMin}
                          open={Boolean(anchorElSfMin)}
                          onClose={this.handleCloseSfMin}
                          data-test-id="handleClose12"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {sqFoot.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              key={option}
                              data-test-id="listhover9"
                              onClick={() =>
                                this.handleOptionClickSfMin(option)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </FormControl>
                  </div>
                  <div style={{ width: "100%" }}>
                    <FormControl style={{ width: "100%", marginTop: "34px" }}>
                      <div>
                        <Button
                          variant="contained"
                          aria-haspopup="true"
                          onClick={this.handleClickSfMax}
                          style={webStyles.buttonStyle as React.CSSProperties}
                        >
                          {selectedOptionSfMax === "" ? (
                            <span style={{ color: "#848FAC", fontWeight: 400 }}>
                              Maximum
                            </span>
                          ) : (
                            <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                              {selectedOptionSfMax}
                            </span>
                          )}
                          <span>
                            <div
                              dangerouslySetInnerHTML={{ __html: svgIcon }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Button>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorElSfMax}
                          open={Boolean(anchorElSfMax)}
                          onClose={this.handleCloseSfMax}
                          data-test-id="handleClose13"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {sqFoot.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              data-test-id="listhover10"
                              key={option}
                              onClick={() =>
                                this.handleOptionClickSfMax(option)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "10px" }}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <Typography
                      style={webStyles.locationHeading as React.CSSProperties}
                    >
                      Property features
                    </Typography>
                    <FormControl style={{ width: "100%", marginTop: "10px" }}>
                      <div>
                        <Button
                          variant="contained"
                          aria-haspopup="true"
                          onClick={this.handleClickPF}
                          style={webStyles.buttonStyle as React.CSSProperties}
                        >
                          {selectedOptionPF === "" ? (
                            <span style={{ color: "#848FAC", fontWeight: 400 }}>
                              Select~
                            </span>
                          ) : (
                            <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                              {selectedOptionPF}
                            </span>
                          )}
                          <span>
                            <div
                              dangerouslySetInnerHTML={{ __html: svgIcon }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Button>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorElPF}
                          open={Boolean(anchorElPF)}
                          onClose={this.handleClosePF}
                          data-test-id="handleClose14"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {proFeatList.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              data-test-id="listhover11"
                              key={option}
                              onClick={() => this.handleOptionClickPF(option)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </FormControl>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "100%", marginRight: "10px" }}>
                    <Typography
                      style={webStyles.locationHeading as React.CSSProperties}
                    >
                      EPC filter rating
                    </Typography>
                    <FormControl
                      style={{
                        width: "100%",
                        marginRight: "10px",
                        marginTop: "10px"
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          aria-haspopup="true"
                          onClick={this.handleClickEcp}
                          style={webStyles.buttonStyle as React.CSSProperties}
                        >
                          {selectedOptionEcp === "" ? (
                            <span style={{ color: "#848FAC", fontWeight: 400 }}>
                              Select~
                            </span>
                          ) : (
                            <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                              {selectedOptionEcp}
                            </span>
                          )}
                          <span>
                            <div
                              dangerouslySetInnerHTML={{ __html: svgIcon }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Button>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorElEcp}
                          open={Boolean(anchorElEcp)}
                          onClose={this.handleCloseEcp}
                          data-test-id="handleClose15"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {epc.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              data-test-id="listhover12"
                              key={option}
                              onClick={() => this.handleOptionClickEcp(option)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </FormControl>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <Typography
                      style={webStyles.locationHeading as React.CSSProperties}
                    >
                      Tenure
                    </Typography>
                    <FormControl style={{ width: "100%", marginTop: "10px" }}>
                      <div>
                        <Button
                          variant="contained"
                          aria-haspopup="true"
                          onClick={this.handleClick2}
                          data-test-id="handleClick2"
                          style={webStyles.buttonStyle as React.CSSProperties}
                        >
                          {selectedOption2 === "" ? (
                            <span style={{ color: "#848FAC", fontWeight: 400 }}>
                              Select~
                            </span>
                          ) : (
                            <span style={{ color: "#1E1E1E", fontWeight: 400 }}>
                              {selectedOption2}
                            </span>
                          )}
                          <span>
                            <div
                              dangerouslySetInnerHTML={{ __html: svgIcon }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Button>
                        <Menu
                          id="custom-select"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={this.handleClose}
                          data-test-id="handleClose3"
                          style={webStyles.menuStyle as React.CSSProperties}
                          className="MenuList"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          {tenureOptions.map((option: string) => (
                            <MenuItem
                              id="listhover"
                              data-test-id="listhover13"
                              key={option}
                              onClick={() => this.handleOptionClick(option)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </FormControl>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      width: "100%",
                      marginTop: "40px",
                      marginLeft: "10px"
                    }}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox name="additionalFilter1" color="primary" />
                        }
                        label="Retirement Homes"
                        style={webStyles.checkboxLabel as React.CSSProperties}
                      />
                    </FormControl>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={4}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      width: "100%",
                      marginTop: "40px",
                      marginLeft: "10px"
                    }}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox name="additionalFilter2" color="primary" />
                        }
                        label="Shared Ownership"
                        style={webStyles.checkboxLabel as React.CSSProperties}
                      />
                    </FormControl>
                  </div>
                  <div
                    style={{
                      marginRight: "10px",
                      width: "100%",
                      marginTop: "40px",
                      marginLeft: "10px"
                    }}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox name="additionalFilter3" color="primary" />
                        }
                        label="New Homes"
                        style={webStyles.checkboxLabel as React.CSSProperties}
                      />
                    </FormControl>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      width: "100%",
                      marginTop: "40px",
                      marginLeft: "10px"
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox name="additionalFilter4" color="primary" />
                      }
                      label="Include Under offer"
                      style={webStyles.checkboxLabel as React.CSSProperties}
                    />
                  </div>
                </Grid>
              </Grid>

              <div style={{ textAlign: "right" }}>
                <Button
                  id="hoverableLight"
                  style={webStyles.submitbutton as React.CSSProperties}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      </Paper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  dropdownBox: {
    backgroundColor: "#FFFFFF",
    marginLeft: "50px",
    marginRight: "50px",
    marginTop: "-100px",
    borderRadius: "8px",
    padding: "30px",
    zIndex: 11,
    position: "relative"
  },
  location: {
    width: "100%",
    height: "56px",
    backgroundColor: "#141D40",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "10px"
  },
  locationHeading: {
    color: "#273567",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    paddingLeft: "5px"
  },
  property: {
    border: "1px solid #273567",
    borderRadius: "8px",
    width: "100%",
    height: "54px",
    marginTop: "10px",
    fontfamily: "Poppins",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    textAlign: "left",
    color: "#1E1E1E"
  },
  size: {
    borderRadius: "8px",
    width: "100%",
    height: "54px",
    marginTop: "35px",
    borderColor: "#848FAC66",
    color: "#848FAC",
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "Poppins",
    lineHeight: "24px",
    border: "1.5px solid #848FAC66"
  },
  showMore: {
    color: "#273567",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    pointer: "cursor",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
    cursor: "pointer"
  },
  buttonStyle: {
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    border: "1px solid lightgrey",
    borderRadius: "8px",
    fontSize: "16px",
    color: "#1E1E1E",
    width: "100%",
    height: "54px",
    fontFamily: "Poppins",
    textTransform: "none",
    paddingRight: "0px",
    lineHeight: "24px",
    fontWeight: "bold"
  },
  buttonPropType: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "54px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #273567",
    boxShadow: "none",
    color: "#1E1E1E",
    width: "100%",
    fontFamily: "Poppins",
    textTransform: "none",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    borderRadius: "8px",
    paddingRight: "0px"
  },
  buttonLocation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "54px",
    backgroundColor: "#141D40",
    border: "1px solid #273567",
    boxShadow: "none",
    color: "#FFFFFF",
    width: "100%",
    fontFamily: "Poppins",
    textTransform: "none",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    borderRadius: "8px",
    paddingRight: "0px"
  },
  menuStyle: {
    marginTop: "55px",
    maxHeight: "457px"
  },
  checkboxLabel: {
    color: "#1E1E1E",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left"
  },
  submitbutton: {
    border: "1px solid #273567",
    color: "#273567",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    marginTop: "15px",
    pointer: "cursor",
    marginBottom: "5px",
    textTransform: "none",
    backgroundColor: "#F4F5F7"
  },
  menulist: {
    background: "#FFFFFF",
    marginTop: "20px",
    borderRadius: "8px",
    zIndex: 1,
    position: "relative",
    marginLeft: "60px",
    width: "120px",
    paddingLeft: "10px",
    paddingRight: "10px"
  }
};
// Customizable Area End
