export const logo = require("../assets/logo.png");
export const background = require("../assets/background.svg");
export const homepic = require("../assets/homepic.png");
export const location = require("../assets/location.png");
export const bed = require("../assets/bed.png");
export const bathroom = require("../assets/bathroom.png");
export const couch = require("../assets/couch.png");
export const cicon = require("../assets/c.png");
export const land = require("../assets/land.png");
export const landwhite = require("../assets/landwhite.png");
export const landblue = require("../assets/landblue.png");
export const gridwhite = require("../assets/gridwhite.png");
export const gridblue = require("../assets/gridblue.png");
export const arrow = require("../assets/arrowdown.png");
export const FirstPic = require("../assets/FirstPic.png");
export const Secpic = require("../assets/Secpic.png");
export const Thirpic = require("../assets/Thirpic.png");
export const viewRect = require("../assets/view_Rect.png");
export const imageOne = require("../assets/imageOne.png");
export const imageTwo = require("../assets/imageTwo.png");
export const locDrop = require("../assets/locDrop.png");