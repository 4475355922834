import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeLink: string;
  selectedLocation: string;
  selectedPropertyType: string;
  selectedPrice: string;
  selectedPriceMaximun: string;
  showMoreFilters: boolean,
  isGridView: boolean,
  limit: string,
  firstTypographyEntered: boolean;
  showFilters: boolean;
  showProperties: boolean;
  scrolledToProperties: boolean;
  isGridVisible: boolean;
  seemoreproperty: boolean;
  loremVisible: boolean;
  bedroomMini: number;
  bedroomMax: number;
  selectedOption: string,
  isOpen: boolean,
  isPriceOpen: boolean;
  isPriceMaxOpen: boolean;
  isBedMiniOpen: boolean;
  isBedMaxOpen: boolean;
  bathroomMini: number;
  bathroomMax: number;
  isBathMiniOpen: boolean;
  isBathMaxOpen: boolean;
  sqFtMini: string;
  sqFtMax: string;
  isSqFtMinOpen: boolean;
  isSqFtMaxOpen: boolean,
  propertyFeature: string;
  isPropFeatOpen: boolean;
  ecp: string;
  isEcpOPen: boolean;
  tenure: string;
  isTenureOpen: boolean;
  islocOpen: boolean;
  sortBy: string;
  isDropdownOpen: boolean;
  anchorEl: null | HTMLElement;
  selectedOption2: string;
  anchorElEcp: null | HTMLElement;
  selectedOptionEcp: string;
  anchorElPF: null | HTMLElement;
  selectedOptionPF: string;
  anchorElSfMax: null | HTMLElement;
  selectedOptionSfMax: string;
  anchorElSfMin: null | HTMLElement;
  selectedOptionSfMin: string;
  anchorElPropList: null | HTMLElement;
  selectedOptionPropList: string;
  anchorElLoc: null | HTMLElement;
  selectedOptionLoc: string;
  anchorElPriceMin: null | HTMLElement;
  selectedOptionPriceMin: string;
  anchorElPriceMax: null | HTMLElement;
  selectedOptionPriceMax: string;
  anchorElBedMin: null | HTMLElement;
  selectedOptionBedMin: string;
  anchorElBedMax: null | HTMLElement;
  selectedOptionBedMax: string;
  anchorElBathMin: null | HTMLElement;
  selectedOptionBathMin: string;
  anchorElBathMax: null | HTMLElement;
  selectedOptionBathMax: string;
  cities:string[];
  anchorElSort: null | HTMLElement;
  selectedOptionSort: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),

    ];

    this.state = {
      activeLink: "Browse Properties",
      selectedLocation: "London",
      islocOpen: false,
      selectedPropertyType: "",
      selectedPrice: "20 L",
      selectedPriceMaximun: "",
      showMoreFilters: false,
      isGridView: true,
      limit: "",
      firstTypographyEntered: false,
      showFilters: false,
      showProperties: false,
      scrolledToProperties: false,
      isGridVisible: false,
      seemoreproperty: false,
      loremVisible: false,
      bedroomMini: 0,
      bedroomMax: 0,
      selectedOption: '',
      isOpen: false,
      isPriceOpen: false,
      isPriceMaxOpen: false,
      isBedMiniOpen: false,
      isBedMaxOpen: false,
      bathroomMini: 0,
      bathroomMax: 0,
      isBathMiniOpen: false,
      isBathMaxOpen: false,
      sqFtMini: '',
      sqFtMax: '',
      isSqFtMinOpen: false,
      isSqFtMaxOpen: false,
      propertyFeature: '',
      isPropFeatOpen: false,
      ecp: '',
      isEcpOPen: false,
      tenure: '',
      isTenureOpen: false,
      sortBy: '',
      isDropdownOpen: false,
      anchorEl: null,
      selectedOption2: '',
      anchorElEcp: null,
      selectedOptionEcp: '',
      anchorElPF: null,
      selectedOptionPF: '',
      anchorElSfMax: null,
      selectedOptionSfMax: '',
      anchorElPropList: null,
      selectedOptionPropList: '',
      anchorElLoc: null,
      selectedOptionLoc: 'London',
      anchorElPriceMin: null,
      selectedOptionPriceMin: '20 L',
      anchorElPriceMax: null,
      selectedOptionPriceMax: '',
      anchorElBedMin: null,
      selectedOptionBedMin: "",
      anchorElBedMax: null,
      selectedOptionBedMax: '',
      anchorElBathMin: null,
      selectedOptionBathMin: '',
      anchorElBathMax: null,
      selectedOptionBathMax: '',
      anchorElSfMin: null,
      selectedOptionSfMin: '',
      anchorElSort: null ,
      selectedOptionSort: '',
      cities:["New York", "Los Angeles", "London"]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  dropdownRef: React.RefObject<HTMLSelectElement> = React.createRef();

  handleFirstTypographyEntered = () => {
    this.setState({ firstTypographyEntered: true }, () => {
      setTimeout(() => {
        this.setState({ showFilters: true }, () => {
          this.handleShowProperties();
        });
      }, 1000);
    });
  }
  handleMyHub=()=>{
      this.props.navigation.navigate("EmailAccountLoginBlock");
  }
  handleShowProperties = () => {
    setTimeout(() => {
      this.setState({ showProperties: true }, () => {
        setTimeout(() => {
          this.setState({ isGridVisible: true, seemoreproperty: true });
        }, 1000);
      });
    }, 1000);
    this.setState({ scrolledToProperties: true });
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleClick = (text: string) => {
    this.setState({ activeLink: text });
  }
 
  handleLimitChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.setState({ limit: event.target.value as string });
  }
  handleShowMoreFilters = () => {
    this.setState((prevState) => ({
      showMoreFilters: !prevState.showMoreFilters,
    }));
  }
  toggleView = () => {
    this.setState((prevState) => ({
      isGridView: !prevState.isGridView,
    }));
  }

  toggleLandscapeView = () => {
    this.setState({
      isGridView: false,
    });
  }

  handleSortChange = (event: { target: { value: string; }; }) => {
    this.setState({ sortBy: event.target.value });
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  }

  closeDropdown = () => {
    this.setState({ isDropdownOpen: false })
  }

  handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleOptionClick = (option: string) => {
    this.setState({
      selectedOption2: option,
      anchorEl: null,
    });
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  handleClickEcp = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElEcp: event.currentTarget });
  }
  handleOptionClickEcp = (option: string) => {
    this.setState({
      selectedOptionEcp: option,
      anchorElEcp: null,
    });
  }
  handleCloseEcp = () => {
    this.setState({ anchorElEcp: null });
  }

  handleClickPF = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElPF: event.currentTarget });
  }
  handleOptionClickPF = (option: string) => {
    this.setState({
      selectedOptionPF: option,
      anchorElPF: null,
    });
  }
  handleClosePF = () => {
    this.setState({ anchorElPF: null });
  }

  handleClickSfMax = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElSfMax: event.currentTarget });
  }
  handleOptionClickSfMax = (option: string) => {
    this.setState({
      selectedOptionSfMax: option,
      anchorElSfMax: null,
    });
  }
  handleCloseSfMax = () => {
    this.setState({ anchorElSfMax: null });
  }

  handleClickSfMin = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElSfMin: event.currentTarget });
  }

  handleOptionClickSfMin = (option: string) => {
    this.setState({
      selectedOptionSfMin: option,
      anchorElSfMin: null,
    });
  }
  handleCloseSfMin = () => {
    this.setState({ anchorElSfMin: null });
  }

  handleClickPropList = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElPropList: event.currentTarget });
  }
  handleOptionClickPropList = (option: string) => {
    this.setState({
      selectedOptionPropList: option,
      anchorElPropList: null,
    });
  }
  handleClosePropList = () => {
    this.setState({ anchorElPropList: null });
  }

  handleClickLoc = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElLoc: event.currentTarget });
  }
  handleOptionClickLoc = (option: string) => {
    this.setState({
      selectedOptionLoc: option,
      anchorElLoc: null,
    });
  }
  handleCloseLoc = () => {
    this.setState({ anchorElLoc: null });
  }

  handleClickPriceMin = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElPriceMin: event.currentTarget });
  }
  handleOptionClickPriceMin = (option: string) => {
    this.setState({
      selectedOptionPriceMin: option,
      anchorElPriceMin: null,
    });
  }
  handleClosePriceMin = () => {
    this.setState({ anchorElPriceMin: null });
  }

  handleClickPriceMax = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElPriceMax: event.currentTarget });
  }
  handleOptionClickPriceMax = (option: string) => {
    this.setState({
      selectedOptionPriceMax: option,
      anchorElPriceMax: null,
    });
  }
  handleClosePriceMax = () => {
    this.setState({ anchorElPriceMax: null });
  }

  handleClickBedMin = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElBedMin: event.currentTarget });
  }
  handleOptionClickBedMin = (option: string) => {
    this.setState({
      selectedOptionBedMin: option,
      anchorElBedMin: null,
    });
  }
  handleCloseBedMin = () => {
    this.setState({ anchorElBedMin: null });
  }

  handleClickBedMax = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElBedMax: event.currentTarget });
  }
  handleOptionClickBedMax = (option: string) => {
    this.setState({
      selectedOptionBedMax: option,
      anchorElBedMax: null,
    });
  }
  handleCloseBedMax = () => {
    this.setState({ anchorElBedMax: null });
  }

  handleClickBathMin = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElBathMin: event.currentTarget });
  }
  handleOptionClickBathMin = (option: string) => {
    this.setState({
      selectedOptionBathMin: option,
      anchorElBathMin: null,
    });
  }
  handleCloseBathMin = () => {
    this.setState({ anchorElBathMin: null });
  }

  handleClickBathMax = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElBathMax: event.currentTarget });
  }
  handleOptionClickBathMax = (option: string) => {
    this.setState({
      selectedOptionBathMax: option,
      anchorElBathMax: null,
    });
  }
  handleCloseBathMax = () => {
    this.setState({ anchorElBathMax: null });
  }
  handleClickSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElSort: event.currentTarget });
  }
  handleOptionClickSort = (option: string) => {
    this.setState({
      selectedOptionSort: option,
      anchorElSort: null,
    });
  }
  handleCloseSort = () => {
    this.setState({ anchorElSort: null });
  }
  handleListProperty=()=>{
    this.props.navigation.navigate("ListMyProperty")
  }
  handlePropertyPage=()=>{
    this.props.navigation.navigate("Catalogue")
  }
  // Customizable Area End
}
