import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export interface CountryCodeResType {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  }
}

export interface CountryCodeResTypeData {
  data:
  [
    {
      id: string;
      type: string;
      attributes: {
        name: string;
        emoji_flag: string;
        country_code: string;
      }
    }
  ]
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selectedOption: string;
  page: number;
  countryCode: string;
  getCountryCodeData: Array<CountryCodeResType>;
  formData: {
    fullPropertyAddress: string;
    monthCount: string;
    fullName: string;
    email: string;
    telephoneNum: string;
    message: string;
    contactPreference: string;
  }
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Customform3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCountryCodeApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      page: 1,
      enableField: false,
      selectedOption: "",
      getCountryCodeData: [],
      countryCode: "",
      formData: {
        fullPropertyAddress: '',
        monthCount: '',
        fullName: '',
        email: '',
        telephoneNum: '',
        message: '',
        contactPreference: '',
      }
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.invalidResponse(responseJson)) {
        this.responseFailure(apiRequestCallId, responseJson);
      }
      else if (this.isValidRes(responseJson)) {
        this.responseSucess(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setPage = (page: number) => {
    this.setState({page: this.state.page + 1})
  }

  setFormData = (field: string, value: string) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [field]: value
      }
    }))
  }

  setSelection = (option: string) => {
    this.setState({selectedOption: option})
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getCountryCodes();
  }
  getCountryCodes = async () => {
    this.getCountryCodeApiCallId = await this.callApi({
      contentType: configJSON.apiGetCountryCodeContentTyp,
      method: configJSON.apiGetCountryMethod,
      endPoint: configJSON.apiGetCountryCodeEndPoint,
    });
  };
  callApi = async (data: any) => {
    const { method, endPoint, body, type = "", contentType } = data;
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);

    if(body && type !== "formData") {
      reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
    } else {
      reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body)
    }

    runEngine.sendMessage(reqMessage.id, reqMessage);

    return reqMessage.messageId;
  };

  isValidRes = (responseJson: CountryCodeResTypeData) => {
    return responseJson && responseJson.data;
  };

  invalidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };

  responseSucess = async (apiRequestCallId: string, responseJson: CountryCodeResTypeData) => {
    if (apiRequestCallId === this.getCountryCodeApiCallId) {
      this.getCountryCodeSuccess(responseJson);
    }
  };

  responseFailure = async (apiRequestCallId: string, responseJson: any ) => {
    if (apiRequestCallId === this.getCountryCodeApiCallId) {
      this.getCountryCodeFailure(responseJson);
    }
  };

  getCountryCodeSuccess = async (response: CountryCodeResTypeData) => {
    this.setState({ getCountryCodeData: response.data });
  };

  getCountryCodeFailure = async (errorReponse: string) => {
    this.setState({ getCountryCodeData: [] });
  };
  // Customizable Area End
}
