import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { FormValues } from "../CustomMemorandumForm.web";
import TextInput from "./TextInput.web";
import RadioButton from "./RadioButton";
import SelectInput from "./SelectInput.web";
import SellerSectionInformation from "./SellerSectionInformation.web";

interface SellerSectionFormProps {
  canEdit: boolean;
}

const SellerSectionForm = ({ canEdit }: SellerSectionFormProps) => {
  const { values, touched, errors, setFieldValue } =
    useFormikContext<FormValues>();

  const selectOptions = [
    { value: false, label: "No" },
    { value: true, label: "Yes" },
  ];

  return (
    <Box style={webStyle.sectionContainer}>
      <Box style={webStyle.sectionHeader}>
        <Box style={webStyle.leftContainer}>
          <Typography style={webStyle.sectionHeaderLabel}>
            Seller Section
          </Typography>
        </Box>
      </Box>
      {canEdit ? (
        <>
          <Box style={webStyle.section}>
            <Typography style={webStyle.sectionLabel}>
              Seller Legal Conveyancer
            </Typography>
            <Box style={webStyle.inputsContainer}>
              <TextInput
                label="*Name"
                name="sellerSection.name"
                value={values.sellerSection.name}
                touched={touched.sellerSection?.name}
                error={errors.sellerSection?.name}
              />
              <TextInput
                label="*Residential/Correspondence Address"
                name="sellerSection.address"
                value={values.sellerSection.address}
                touched={touched.sellerSection?.address}
                error={errors.sellerSection?.address}
              />
              <TextInput
                label="*Phone Number"
                name="sellerSection.phoneNumber"
                value={values.sellerSection.phoneNumber}
                touched={touched.sellerSection?.phoneNumber}
                error={errors.sellerSection?.phoneNumber}
              />
              <TextInput
                label="*Email"
                name="sellerSection.email"
                value={values.sellerSection.email}
                touched={touched.sellerSection?.email}
                error={errors.sellerSection?.email}
              />
            </Box>
          </Box>
          <Box style={webStyle.section}>
            <Typography style={webStyle.sectionLabel}>
              Other Information
            </Typography>
            <Typography style={webStyle.label}>
              *Will a holding deposit be paid?
            </Typography>
            <Box style={webStyle.radiosContainer}>
              <Box style={webStyle.radioContainer}>
                <RadioButton
                  testId="holding-deposit-yes"
                  checked={
                    values.sellerSectionOtherInformation
                      .holdingDepositWillBePaid
                  }
                  onChange={() =>
                    setFieldValue(
                      "sellerSectionOtherInformation.holdingDepositWillBePaid",
                      true
                    )
                  }
                />
                <Typography style={webStyle.label}>Yes</Typography>
              </Box>
              <Box style={webStyle.radioContainer}>
                <RadioButton
                  testId="holding-deposit-no"
                  checked={
                    !values.sellerSectionOtherInformation
                      .holdingDepositWillBePaid
                  }
                  onChange={() => {
                    setFieldValue(
                      "sellerSectionOtherInformation.holdingDepositWillBePaid",
                      false
                    );
                  }}
                />
                <Typography style={webStyle.label}>No</Typography>
              </Box>
            </Box>
            {values.sellerSectionOtherInformation.holdingDepositWillBePaid && (
              <Box style={{ marginBottom: "30px" }}>
                <TextInput
                  label="If yes, how much will be paid?"
                  name="sellerSectionOtherInformation.howMuchWillBePaid"
                  value={values.sellerSectionOtherInformation.howMuchWillBePaid}
                  touched={
                    touched.sellerSectionOtherInformation?.howMuchWillBePaid
                  }
                  error={
                    errors.sellerSectionOtherInformation?.howMuchWillBePaid
                  }
                  fullWidth
                />
              </Box>
            )}
            <Box style={webStyle.inputsContainer}>
              <SelectInput
                label="*Has the deposit been verified/Confirmed?"
                name="sellerSectionOtherInformation.depositVerified"
                value={selectOptions.find(
                  (option) =>
                    option.value ===
                    values.sellerSectionOtherInformation?.depositVerified
                )}
                touched={touched.sellerSectionOtherInformation?.depositVerified}
                error={errors.sellerSectionOtherInformation?.depositVerified}
                options={selectOptions}
              />
              <TextInput
                label="*Expected date for exchange"
                name="sellerSectionOtherInformation.exchangeDate"
                value={values.sellerSectionOtherInformation.exchangeDate}
                touched={touched.sellerSectionOtherInformation?.exchangeDate}
                error={errors.sellerSectionOtherInformation?.exchangeDate}
                inputType="date"
              />
              <TextInput
                label="*Expected date of completion"
                name="sellerSectionOtherInformation.completionDate"
                value={values.sellerSectionOtherInformation.completionDate}
                touched={touched.sellerSectionOtherInformation?.completionDate}
                error={errors.sellerSectionOtherInformation?.completionDate}
                inputType="date"
              />
              <TextInput
                label="*Is the Vendor buying another property?"
                name="sellerSectionOtherInformation.vendorAnotherProperty"
                value={
                  values.sellerSectionOtherInformation.vendorAnotherProperty
                }
                touched={
                  touched.sellerSectionOtherInformation?.vendorAnotherProperty
                }
                error={
                  errors.sellerSectionOtherInformation?.vendorAnotherProperty
                }
              />
            </Box>
            <Box
              style={{
                ...webStyle.inputsContainer,
                marginBottom: "30px",
                marginTop: "30px",
              }}
            >
              <TextInput
                label="Special conditions for sale"
                name="sellerSectionOtherInformation.specialConditions"
                value={values.sellerSectionOtherInformation.specialConditions}
                touched={
                  touched.sellerSectionOtherInformation?.specialConditions
                }
                error={errors.sellerSectionOtherInformation?.specialConditions}
                isTextArea
              />
              <TextInput
                label="*Details of the Onward chase"
                name="sellerSectionOtherInformation.chaseDetails"
                value={values.sellerSectionOtherInformation.chaseDetails}
                touched={touched.sellerSectionOtherInformation?.chaseDetails}
                error={errors.sellerSectionOtherInformation?.chaseDetails}
                isTextArea
              />
            </Box>
            <Typography style={webStyle.label}>
              *Any Legal issues to Note?.
            </Typography>
            <Box style={webStyle.radiosContainer}>
              <Box style={webStyle.radioContainer}>
                <RadioButton
                
                  checked={values.sellerSectionOtherInformation.haveLegalIssues}
                  onChange={() =>
                    setFieldValue(
                      "sellerSectionOtherInformation.haveLegalIssues",
                      true
                    )
                  }
                  testId="have-issues-yes"
                />
                <Typography style={webStyle.label}>Yes</Typography>
              </Box>
              <Box style={webStyle.radioContainer}>
                <RadioButton
                  checked={
                    !values.sellerSectionOtherInformation.haveLegalIssues
                  }
                  onChange={() =>
                    setFieldValue(
                      "sellerSectionOtherInformation.haveLegalIssues",
                      false
                    )
                  }
                  testId="have-issues-no"
                />
                <Typography style={webStyle.label}>No</Typography>
              </Box>
            </Box>
            {values.sellerSectionOtherInformation.haveLegalIssues && (
              <Box style={{ marginBottom: "30px" }}>
                <TextInput
                  label="If yes, please specify"
                  name="sellerSectionOtherInformation.legalIssues"
                  value={values.sellerSectionOtherInformation.legalIssues}
                  touched={touched.sellerSectionOtherInformation?.legalIssues}
                  error={errors.sellerSectionOtherInformation?.legalIssues}
                  fullWidth
                />
              </Box>
            )}
            <Box style={webStyle.inputsContainer}>
              <TextInput
                label="*Fixture and fittings including in the sale"
                name="sellerSectionOtherInformation.fixtureFittingsOnSale"
                value={
                  values.sellerSectionOtherInformation.fixtureFittingsOnSale
                }
                touched={
                  touched.sellerSectionOtherInformation?.fixtureFittingsOnSale
                }
                error={
                  errors.sellerSectionOtherInformation?.fixtureFittingsOnSale
                }
              />
              <TextInput
                label="Extra notes/Observations"
                name="sellerSectionOtherInformation.extraNotes"
                value={values.sellerSectionOtherInformation.extraNotes}
                touched={touched.sellerSectionOtherInformation?.extraNotes}
                error={errors.sellerSectionOtherInformation?.extraNotes}
              />
            </Box>
          </Box>
        </>
      ) : (
        <SellerSectionInformation />
      )}
    </Box>
  );
};

interface StylesDictionary {
  [Key: string]: React.CSSProperties;
}

const webStyle: StylesDictionary = {
  sectionContainer: {
    marginBottom: "32px",
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "rgba(0, 151, 203, 0.05)",
    padding: "10px 15px",
    borderRadius: "8px",
    alignItems: "center",
    marginBottom: "22px",
  },
  sectionHeaderLabel: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    color: "#273567",
  },
  leftContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  sectionLabel: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000",
    marginBottom: "16px",
  },
  label: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    color: "#273567",
  },
  inputsContainer: {
    display: "flex",
    gap: "30px",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  inputContainer: {
    width: "48%",
  },
  section: {
    marginBottom: "16px",
  },
  radioContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    width: "20%",
  },
  radiosContainer: {
    display: "flex",
    gap: "30px",
    marginTop: "16px",
    marginBottom: "16px",
  },
  radio: {
    padding: "unset",
    width: "20px",
    height: "20px",
  },
};

export default SellerSectionForm;
